import { createStore } from "vuex";
import { config } from "vuex-module-decorators";
import axios from 'axios'

import MasterIjp from "@/store/modules/MasterIjp";
import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ProfilingModule from "@/store/modules/ProfilingModule";
import EditProfilingModule from "@/store/modules/EditProfilingModule"
import FilterPermohonanModule from "@/store/modules/FilterPermohonanModule"
import KeuanganModule from "./modules/KeuanganModule";
import CmsModule from "@/store/modules/CmsModule";
import SlikModule from "./modules/SlikModule";
import ScoringModule from "@/store/modules/ScoringModule";
import MasterMitra from "@/store/modules/MasterMitra";
import LoggedUser from "./modules/LoggedUser";
import Layout from "./modules/Layout";
import router from "@/router";
import Swal from "sweetalert2";
import cookie from 'js-cookie'

import task from '@/assets/img/icons/Icon-task.svg'


config.rawError = true;


const store = createStore({
  state: {
    statusProfiling: 0,
    counter : {
      slik : [],
      form_nomor: null, // requiredd
      kadiv: null, // requiredd
      analisis: null, // requiredd
      id_kabag: null, // requiredd
      nama_calon_terjamin : null, // requiredd
      alamat_calon_terjamin : null, // requiredd
      tahun_berdiri : null,
      no_telepon : null,
      direktur_utama : null,
      direktur_1 : null,
      direktur_2 : null,
      komisaris_utama : null,
      komisaris : null,
      jumlah_karyawan : null,
      jumlah_direksi : null,
      npwp : null,
      tdp : null,
      siup : null,
      nib : null,
      akta_pendirian : null,
      akta_perubahan_terakhir : null,
      nama_mitra : null,
      alamat_mitra : null,
      tgl_surat_permohonan : null,
      no_surat : null,
      date_approved : null,
      fasilitas_kredit : null,
      plafon_kredit : null, // requiredd
      suku_bunga : null, // requiredd
      jangka_waktu_fasilitas : null,
      masa_penarikan : null,
      jangka_waktu : null,
      sifat_kredit : null,
      cara_penarikan : null,
      pembayaran : null,
      tujuan_penggunaan : null,
      jaminan_colateral : null,
      dynamic_profiling_field: [],
      kredit_struktur_field:[]
    },
    tgl_kelengkapan: null,
    formNomorInput : "",
    indexId: '',
    indexInput: [],
    post_status_error: null,
    nomor : '',
    variants : [{variant : 'default'}],
    viewOnly: false,
    keuanganTemplates: [],
    keuanganLoading: false,
    scoringLoading: false,
    keuanganData: [],
    keuanganTahun : [],
    quiz: [],
    answer: [],
    resumeLoading: {
      view: false,
      update: false
    },
    scoring: '',
    saveLoading: false,
    submitLoading:false,
    sum: [],
    group: ["01", "02", "03", "04"],
    dropdown: {},
    keuangan: {
      data: [],
      years: []
    },
    indexList: [],
    selectedVariant: '',
    loadingCardQuestion: false,
    templates: [],
    token: null,
    selectedActive: false,
    activeProfiling: {
      id_profiling: "",
      id_permohonan: "",
      form_nomor: "",
      id_staff: "",
      id_kabag: "",
      id_dirut: "",
      nama_penerima_jaminan: "",
      alamat_penerima_jaminan: "",
      id_jenis_kredit: "",
      tgl_permohonan: "",
      no_permohonan: "",
      tahun: [],
      bruhMoment: false
    },
    historyProfiler : [],
    activeButtonBackAddScoring: false,
    titleAddScoring: [
      {
        text: 'Profiling', 
        svg: '<svg width="26" height="26" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6.65125 23.005C9.27427 21.5224 12.237 20.7454 15.25 20.75C18.375 20.75 21.3087 21.5688 23.8487 23.005M19 13.25C19 14.2446 18.6049 15.1984 17.9017 15.9017C17.1984 16.6049 16.2446 17 15.25 17C14.2554 17 13.3016 16.6049 12.5983 15.9017C11.8951 15.1984 11.5 14.2446 11.5 13.25C11.5 12.2554 11.8951 11.3016 12.5983 10.5983C13.3016 9.89509 14.2554 9.5 15.25 9.5C16.2446 9.5 17.1984 9.89509 17.9017 10.5983C18.6049 11.3016 19 12.2554 19 13.25ZM26.5 15.75C26.5 17.2274 26.209 18.6903 25.6436 20.0552C25.0783 21.4201 24.2496 22.6603 23.205 23.705C22.1603 24.7496 20.9201 25.5783 19.5552 26.1436C18.1903 26.709 16.7274 27 15.25 27C13.7726 27 12.3097 26.709 10.9448 26.1436C9.5799 25.5783 8.33971 24.7496 7.29505 23.705C6.25039 22.6603 5.42172 21.4201 4.85636 20.0552C4.29099 18.6903 4 17.2274 4 15.75C4 12.7663 5.18526 9.90483 7.29505 7.79505C9.40483 5.68526 12.2663 4.5 15.25 4.5C18.2337 4.5 21.0952 5.68526 23.205 7.79505C25.3147 9.90483 26.5 12.7663 26.5 15.75Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
      }, 
      {
      text: 'Keuangan',
      svg: '(Rp)'
      },
      { 
        text: 'Kepribadian',
        svg: '<svg width="26" height="26" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.75 9C20.75 10.3261 20.2232 11.5979 19.2855 12.5355C18.3479 13.4732 17.0761 14 15.75 14C14.4239 14 13.1521 13.4732 12.2145 12.5355C11.2768 11.5979 10.75 10.3261 10.75 9C10.75 7.67392 11.2768 6.40215 12.2145 5.46447C13.1521 4.52678 14.4239 4 15.75 4C17.0761 4 18.3479 4.52678 19.2855 5.46447C20.2232 6.40215 20.75 7.67392 20.75 9ZM15.75 17.75C13.4294 17.75 11.2038 18.6719 9.56282 20.3128C7.92187 21.9538 7 24.1794 7 26.5H24.5C24.5 24.1794 23.5781 21.9538 21.9372 20.3128C20.2962 18.6719 18.0706 17.75 15.75 17.75Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
      }, 
      {
        text: 'Prospek',
        svg: '<svg width="26" height="26" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.845 20.7423C18.5266 20.7423 18.2213 20.8687 17.9963 21.0938C17.7712 21.3189 17.6447 21.6242 17.6447 21.9425C17.6447 22.2608 17.7712 22.5661 17.9963 22.7912C18.2213 23.0163 18.5266 23.1427 18.845 23.1427H24.8462C25.1645 23.1427 25.4698 23.0163 25.6949 22.7912C25.92 22.5661 26.0464 22.2608 26.0464 21.9425V15.9413C26.0464 15.6229 25.92 15.3177 25.6949 15.0926C25.4698 14.8675 25.1645 14.741 24.8462 14.741C24.5279 14.741 24.2226 14.8675 23.9975 15.0926C23.7724 15.3177 23.6459 15.6229 23.6459 15.9413V19.0451L18.4933 13.8924C18.2682 13.6674 17.963 13.541 17.6447 13.541C17.3264 13.541 17.0212 13.6674 16.7961 13.8924L14.044 16.6446L8.89131 11.492C8.66494 11.2733 8.36176 11.1523 8.04706 11.1551C7.73235 11.1578 7.43132 11.284 7.20878 11.5066C6.98625 11.7291 6.86002 12.0301 6.85728 12.3448C6.85455 12.6595 6.97553 12.9627 7.19416 13.1891L13.1954 19.1903C13.4205 19.4153 13.7257 19.5417 14.044 19.5417C14.3622 19.5417 14.6675 19.4153 14.8925 19.1903L17.6447 16.4382L21.9488 20.7423H18.845Z" fill="#C8C8C8"/><path d="M17 9H26.6M26.6 9V18.6M26.6 9L17 18.6L12.2 13.8L5 21" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
      }, 
      {
        text: 'Produktifitas',
        svg: '<svg width="26" height="26" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M26.5 18.0688C22.9256 19.514 19.1055 20.2547 15.25 20.25C11.2712 20.25 7.475 19.475 4 18.0688M20.25 9V6.5C20.25 5.83696 19.9866 5.20107 19.5178 4.73223C19.0489 4.26339 18.413 4 17.75 4H12.75C12.087 4 11.4511 4.26339 10.9822 4.73223C10.5134 5.20107 10.25 5.83696 10.25 6.5V9M15.25 16.5H15.2625M6.5 26.5H24C24.663 26.5 25.2989 26.2366 25.7678 25.7678C26.2366 25.2989 26.5 24.663 26.5 24V11.5C26.5 10.837 26.2366 10.2011 25.7678 9.73223C25.2989 9.26339 24.663 9 24 9H6.5C5.83696 9 5.20107 9.26339 4.73223 9.73223C4.26339 10.2011 4 10.837 4 11.5V24C4 24.663 4.26339 25.2989 4.73223 25.7678C5.20107 26.2366 5.83696 26.5 6.5 26.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
      }, 
      {
        text: 'Pembayaran',
        svg: '<svg width="26" height="26" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 13.25H26.5M9 19.5H10.25M15.25 19.5H16.5M7.75 24.5H22.75C23.7446 24.5 24.6984 24.1049 25.4017 23.4017C26.1049 22.6984 26.5 21.7446 26.5 20.75V10.75C26.5 9.75544 26.1049 8.80161 25.4017 8.09835C24.6984 7.39509 23.7446 7 22.75 7H7.75C6.75544 7 5.80161 7.39509 5.09835 8.09835C4.39509 8.80161 4 9.75544 4 10.75V20.75C4 21.7446 4.39509 22.6984 5.09835 23.4017C5.80161 24.1049 6.75544 24.5 7.75 24.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
      }]
  },
  getters: {
  },
  mutations: {
    RESET2(state){
      state.answer = []
      state.sum = []
      state.selectedActive = false
    },
    RESET(state){
      state.keuanganData = [],
      state.nomor = "",
      state.answer = []
      state.sum = []
      state.selectedActive = false,
      state.quiz = [],
      state.activeProfiling = {},
      state.selectedVariant = ''
    },
    ADD_ANSWER(state,n) {
      state.answer = n
    },
    SET_DROPDOWN (state,n) {
      state.dropdown = n
    },
    SET_ACTIVE_PROFILING (state, n) {
      state.activeProfiling = n
    },
    SET_ACTIVE_QUIZ (state, n) {
      state.quiz = n
    },
    SET_KEUANGAN (state, n) {
      state.keuangan = n
    },
    SET_TAHUN(state, n) {
      state.tahun = n
    },
    SET_TEMPLATE (state, n) {
      state.templates = n
    },
    RESET3(state){
      state.profiling_status = 0,
      state.keuanganData = [],
      state.nomor = "",
      state.answer = []
      state.sum = []
      state.selectedActive = false,
      state.quiz = [],
      state.activeProfiling = {},
      state.selectedVariant = ''
    },
    SET_KEUANGANN (state, n){
      state.keuanganData = n.data
      state.keuanganTahun = n.years
      state.keuanganTemplates = n.temp
    },
    RESET_ACTIVE_PROFILING(state) {
      state.activeProfiling = {
        id_profiling: "",
        id_permohonan: "",
        form_nomor: "",
        id_staff: "",
        id_kabag: "",
        id_dirut: "",
        id_jenis_kredit: "",
        nama_penerima_jaminan: "",
        alamat_penerima_jaminan: "",
        tgl_permohonan: "",
        no_permohonan: ""
      }
    },
    setAlamatTerjamin(state, val){
      state.counter["alamat_calon_terjamin"]=val
    },
    setTahunBerdiri(state, val){
      state.counter["tahun_berdiri"]=val
    },
    setDirektur1(state, val){
      state.counter["direktur_1"]=val
    },
    setDirektur2(state, val){
      state.counter["direktur_2"]=val
    },
    setKomisarisUtama(state, val){
      state.counter["komisaris_utama"]=val
    },
    setKomisaris(state, val){
      state.counter["komisaris"]=val
    },
    setJmlKaryawan(state, val){
      state.counter["jumlah_karyawan"] = val
    },
    setJmlDireksi(state, val){
      state.counter["jumlah_direksi"] = val
    },
    setAktPendirian(state, val){
      state.counter["akta_pendirian"] = val
    },
    setNPWP(state, val){
      state.counter["npwp"] = val
    },
    setTDP(state, val){
      state.counter["tdp"] = val
    },
    setSIUP(state, val){
      state.counter["siup"] = val
    },
    setFasilitasKredit(state, val){
      state.counter["fasilitas_kredit"] = val
    },
    setSukuBunga(state, val){
      state.counter["suku_bunga"] = val
    },
    setJangkaWaktuFasilitas(state, val){
      state.counter["jangka_waktu_fasilitas"] = val
    },
    setMasaPenarikan(state, val){
      state.counter["masa_penarikan"] = val
    },
    setJangkaWaktuKredit(state, val){
      state.counter["jangka_waktu_kredit"] = val
    },
    setSifatKredit(state, val){
      state.counter["sifat_kredit"] = val
    },
    setCaraPenarikan(state, val){
      state.counter["cara_penarikan"] = val
    },
    setPembayaran(state, val){
      state.counter["pembayaran"] = val
    },
    setTujuanPenggunaan(state, val){
      state.counter["tujuan_penggunaan"] = val
    },
    setJaminan(state, val){
      state.counter["jaminan"] = val
    },
    setDate(state, val){
      state.counter["form_date"] = val
    },
    setOptField(state, {val, key, id_field, prop}){
      if(prop == 'kredit'){
        const foundItem = state.counter["kredit_struktur_field"].find((item)=>{
          return item.key === key 
        })
        if(!foundItem){
          state.counter["kredit_struktur_field"].push({key: key, value: val, id_field: id_field})
          return
        }

        let valUpt = ( k,vl ) => {
          for (var i in state.counter["kredit_struktur_field"]) {
            if (state.counter["kredit_struktur_field"][i].key == k ) {
              state.counter["kredit_struktur_field"][i].value = vl;
                break; //Stop this loop, we found it!
            }
          }
        }

        valUpt(key,val)
      }else{
        const foundItem = state.counter["dynamic_profiling_field"].find((item)=>{
          return item.key === key 
        })
        if(!foundItem){
          state.counter["dynamic_profiling_field"].push({key: key, value: val, id_field: id_field})
          return
        }

        let valUpt = ( k,vl ) => {
          for (var i in state.counter["dynamic_profiling_field"]) {
            if (state.counter["dynamic_profiling_field"][i].key == k ) {
              state.counter["dynamic_profiling_field"][i].value = vl;
                break; //Stop this loop, we found it!
            }
          }
        }

        valUpt(key,val)
      }
    },
    setUniv(state, {key, val}){
      state.counter[key] =  val
    },
    setKelengkapan(state, val){
      state.tgl_kelengkapan = val
    },
    setCounterUniv(state, {key, val}){
      state[key]=val
    }
  },
  actions: {
    checkAuth(context){
      axios.get(process.env.VUE_APP_BASE_API+"check-auth",{
        params : {
          secret_code : cookie.get('token')
        }
      }).then((response)=>{
        console.log(response)
        cookie.set("access_token",response.data.token)
        context.state.bruhMoment = true
        cookie.remove('token')
      })
    },
      async InitPermohonan(context, val){
        try {
          const dataRes = await axios.get(process.env.VUE_APP_APIORIGIN+'/permohonan/no-permohonan', { params: { no_permohonan: val },  } )

          context.state.statusProfiling = dataRes.data.profiling_status
          context.state.historyProfiler = dataRes.data.history
          const dataOne = await axios.get(process.env.VUE_APP_APIORIGIN+'/scoring/initIndex', { params: { no_permohonan: val } } );
          context.state.indexList = dataOne.data.list
          context.state.indexId = dataOne.data.default
          context.state.indexInput = dataOne.data.data
          if(dataRes.data.profiling_status == 1){
            const DataTwo = await axios.get(process.env.VUE_APP_APIORIGIN+'/profiling/per/' + val  )

            await context.dispatch('getProfilingById', DataTwo.data.data.id_profiling)
            // context.commit('EditProfilingModule/setVar', {key:"body" ,val: DataTwo.data.data})
            // context.commit('EditProfilingModule/setVar', {key:"date_approved", val:DataTwo.data.date_approved})
            
            // const dataThree = await axios.get(process.env.VUE_APP_APIORIGIN+'/profiling/staff' )
            // this.staff_analisis = dataThree.data.analisis
            //   this.staff_kadiv = dataThree.data.kediv
            //   this.staff_kabag = dataThree.data.kabag

            let getScoring = true
            if(dataRes.data.ketentuan_mitra != 'specific'){
              context.state.keuanganLoading = true
              const dataFour = await axios.get(process.env.VUE_APP_APIORIGIN+'/keuangan/getprofile/'+DataTwo.data.data.id_profiling )
              context.state.KeuanganModule.activeProfile = DataTwo.data.data.id_profiling
              
              context.state.KeuanganModule.status = dataFour.data.exist
              context.state.KeuanganModule.skema = dataFour.data.skema
              context.dispatch('KeuanganModule/getTemplates').then(() => context.state.keuanganLoading = false);

              getScoring = dataFour.data.exist
            } 

            if (getScoring) {
              context.dispatch('setActive',DataTwo.data.data.id_profiling)
            }
        
          } else {
            if(dataRes.data.id_form_profiling != 0){
              await axios.get(process.env.VUE_APP_APIORIGIN + '/profiling/custom-field-form-profiling', {
                params: {
                  id_form_profiling: dataRes.data.id_form_profiling
                }
              }).then(res => {
                let dataField = res.data.data;
                context.dispatch('generateTreeResponseCustomField', {dataArray: dataField, data: dataRes.data})
              })
            }
          }
          context.commit('setUniv', {key:"nama_mitra" ,val: dataRes.data.nama_mitra})
          context.commit('setUniv', {key:"alamat_mitra" ,val: dataRes.data.alamat_mitra})
          context.commit('setUniv', {key:"mitra_id" ,val: dataRes.data.mitra_id})
          context.commit('setUniv', {key:"id_jenis_kredit" ,val: dataRes.data.id_jenis_kredit})
          context.commit('setUniv', {key:"nama_jenis_kredit" ,val: dataRes.data.nama_jenis_kredit})
          context.commit('setUniv', {key:"ketentuan_mitra" ,val: dataRes.data.ketentuan_mitra})
          context.commit('setUniv', {key:"tgl_surat_permohonan", val:dataRes.data.tgl_surat_permohonan})
          context.commit('setUniv', {key:"no_surat", val:dataRes.data.no_surat})
          context.commit('setUniv', {key:"fasilitas_kredit", val:dataRes.data.fasilitas_kredit})
          context.commit('setUniv', {key:"jangka_waktu", val:dataRes.data.jangka_waktu})
          context.commit('setUniv', {key:"date_approved", val:dataRes.data.date_approved})
          context.commit('setUniv', {key:"plafon_kredit", val:dataRes.data.plafon_kredit})
          context.commit('setUniv', {key:"nama_calon_terjamin", val:dataRes.data.nama_calon_terjamin})
          context.commit('setKelengkapan',  dataRes.data.tgl_approve)
        } catch (error) {
          console.log(error)
        }
      },
      getProfilingById(context, id) {
        return new Promise((resolve, reject) => {
          axios.get(process.env.VUE_APP_APIORIGIN+'/profiling/' + id)
          .then((e)=>{
              e.data.data.custom_field.forEach((node) => {
                  context.dispatch('generateTreeResponseCustomField', {dataArray: node, data: null})
              })
              context.commit('EditProfilingModule/setVar', {key:"body" ,val: e.data.data})
              context.commit('EditProfilingModule/setVar', {key:"date_approved" ,val: e.data.date_approved})
              resolve(e)
          }).catch(e =>{
              console.log(e)
              reject(e)
          })
        })
      },
      generateTreeResponseCustomField(context, {dataArray, data}){
        let dataTree = []
        dataArray.forEach((item) => {

          if(data){
            if(item.key_reference != null) {
              item.value = data[item.key_reference]
            }
          }

          if(item.type_form == 'profiling' && item.id_field != 0) context.commit('ProfilingModule/setvar', {key: 'activeCustomProfiling', val: true})
          if(item.type_form == 'kredit' && item.id_field != 0) context.commit('ProfilingModule/setvar', {key: 'activeCustomStructurCredit', val: true})

          if(dataTree[item.type_form] === undefined){
            dataTree[item.type_form] = [item]
          } else {
            dataTree[item.type_form].push(item)
          }
        })

        if(dataTree['profiling'] !== undefined) context.commit('ProfilingModule/setvar', {key: 'dynamicFieldFormProfiling', val: dataTree['profiling']})
        if(dataTree['kredit'] !== undefined) context.commit('ProfilingModule/setvar', {key: 'dynamicFieldFormStructurCredit', val: dataTree['kredit']})
      },
      generateDataCustomField(context, {arrayCustom, arrayData}){
        let values = arrayCustom.map((item) => {
          if(item.value == undefined) {
            item.value = ""
          }

          if(item.key_reference != null) {
            arrayData[item.key_reference] = item.value
          }
          return {key_field: item.name, value: item.value, urutan: item.urutan, id_field: item.id_field}
        })
  
        return values;
      },
      setFormNomor(context, payload) {
        context.state.nomor = payload
      },
      reseting (context) {
        context.commit('RESET')
      },
      async printResume (context) {
        axios.get()
      },
      async editResume (context, type) {

        context.state.activeButtonBackAddScoring = true
        context.state.viewOnly = context.state.ScoringModule.detail.data.status_scoring == 2  ? true : false
        context.state.resumeLoading[type] = true
        context.state.indexId = context.state.ScoringModule.detail.data.id_group_index
        context.state.indexInput = context.state.ScoringModule.detail.index
        context.state.formNomorInput = context.state.ScoringModule.detail.data.nomor
        context.state.scoring = type
        context.dispatch('InitPermohonan', context.state.ScoringModule.detail.data.no_permohonan).then(() => router.push(`/scoring/add-scoring?id=${context.state.ScoringModule.detail.data.id_list_scoring}`)).then(() => context.state.resumeLoading[type] = false )

      },
      
      async profilingGet(context){
        let profile = [];
        await axios.get(process.env.VUE_APP_API_SP3 + "profiling/dropdown" )
                   .then(response => {profile = response.data.data.permohonan})
                   .then(() => context.commit('SET_DROPDOWN', profile));
      }, 
      async submitHandler (context, payload){
        if (!context.state.submitLoading && !context.state.saveLoading) {
          payload == 1 ? context.state.saveLoading = true : context.state.submitLoading = true
          let test = {
            nomor : context.state.activeProfiling.form_nomor,
            form_nomor: context.state.activeProfiling.form_nomor,
            status_scoring: payload,
            id: context.state.ScoringModule.detail.data ? context.state.ScoringModule.detail.data.id_list_scoring : '',
            id_profiling : context.state.activeProfiling.id_profiling,
            id_jenis_kredit : context.state.activeProfiling.id_jenis_kredit,
            nama_nasabah: context.state.activeProfiling.nama_nasabah,
            mitra : context.state.activeProfiling.mitra_id,
            jenis: context.state.activeProfiling.id_jenis_kredit,
            kredit: context.state.activeProfiling.kredit,
            index: context.state.indexId,
            answers : []
          }
          let i = 0
          context.state.answer.map( ans => {
            test.answers.push(
              {
                group_id : context.state.group[i],
                data : ans
              }
            )
            i++
          })

          await axios.post(process.env.VUE_APP_API_SP3 + "profiling/scoring", test )        
          .then(() => {
            payload == 1 ? context.state.saveLoading = false : context.state.submitLoading = false

            Swal.fire({
              text: "Scoring telah tersimpan",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Kembali ke list",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(() => {
              context.state.viewOnly = false
              router.push('/scoring/list-scoring');
              context.commit('RESET')
            });
          })
          .catch(err => {
            console.log(err)
            payload == 1 ? context.state.saveLoading = false : context.state.submitLoading = false
            Swal.fire({
              text: "Terjadi Kesalahan Jaringan",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Coba lagi",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            })
          })
        }
      },
      async setActive(context, payload){
        return new Promise((resolve, reject) => {

          context.state.scoringLoading = true
          context.commit('RESET')
          let active = {}

          axios.get(process.env.VUE_APP_API_SP3 + "profiling/detail/" + payload )
          .then(response => {

            active = response.data.data
            context.commit('SET_ACTIVE_PROFILING',active)

            axios.get(process.env.VUE_APP_API_SP3 + "pertanyaan/read", {params: {
              kredit: context.state.activeProfiling.id_jenis_kredit,
              id: context.state.ScoringModule.detail.data ? context.state.ScoringModule.detail.data.id_list_scoring : '',
              variant: context.state.selectedVariant,
              id_profiling: context.state.activeProfiling.id_profiling
            }}).then(response => {

              context.commit('SET_ACTIVE_QUIZ', response.data.data)
              context.state.selectedVariant = response.data.variant
              context.state.variants = response.data.variants

              if (response.data.answers.length == 0 ) {
                context.dispatch('initAnswer', response.data.data)
              }else{
                context.commit('ADD_ANSWER', response.data.answers)
                context.state.sum = [0,0,0,0]
                context.state.nomor = context.state.ScoringModule.detail.data.nomor
                for (let index = 0; index < context.state.answer.length; index++) {
                  context.state.sum[index] = context.state.answer[index].reduce((partialSum, a) => partialSum + a.data, 0);
                }
              }

              context.state.scoringLoading = false
              context.state.selectedActive = true 

            })

            resolve(response)
          })

        }).catch(error => {
          context.state.scoringLoading = false
          context.commit('RESET_ACTIVE_PROFILING')
          Swal.fire({
            text: "Terjadi Kesalahan Jaringan",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Coba lagi",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          })

          reject(error)
        })
      },
      setVariant(context){
        return new Promise( async (resolve, reject) => {

          context.state.loadingCardQuestion = true

          await axios.get(process.env.VUE_APP_API_SP3 + "pertanyaan/read", {params: {
            kredit: context.state.activeProfiling.id_jenis_kredit,
            id: '',
            variant: context.state.selectedVariant,
            id_profiling: context.state.activeProfiling.id_profiling
          }}).then(response => {

            let dataPertanyaanVariant = response.data.data

            context.state.quiz.forEach((itemGroup, indexGroup) => {
              let itemDelete = []
              itemGroup.data.forEach((itemPertanyaan) => {
                if(itemPertanyaan.variant != 'default' && itemPertanyaan.variant != context.state.selectedVariant) {
                  itemDelete.push(itemPertanyaan.id)
                }
              })

              if(itemDelete.length != 0) {
                itemGroup.data = itemGroup.data.filter(item => !itemDelete.includes(item.id))
                itemDelete.forEach(id => {
                  context.state.sum[indexGroup] -= context.state.answer[indexGroup].filter(item => item.question_id == id)[0].data
                })
                context.state.answer[indexGroup] = context.state.answer[indexGroup].filter(item => !itemDelete.includes(item.question_id))
              }
            })

            dataPertanyaanVariant.forEach((itemGroup, indexGroup) => {
              itemGroup.data.forEach((itemPertanyaan, indexPertanyaan) => {
                let checkDataExist = context.state.quiz[indexGroup].data.filter( node => {
                  return node.id == itemPertanyaan.id
                })[0]

                if(!checkDataExist){
                  context.state.quiz[indexGroup].data.splice(indexPertanyaan, 0, itemPertanyaan)

                  let answer 
                  let tempo = 0 
                  if(itemPertanyaan.rumus == null){ 
                    answer = {
                      question_id: itemPertanyaan.id,
                      data: 0,
                      urutan : indexPertanyaan.urutan
                    }
                  }
                  else{
                    try {
                      itemPertanyaan.autofill.map(
                        test => {
                          eval(test.req.replace(new RegExp('{'+ 'var' +'}','g'), eval(itemPertanyaan.rumus))) ? tempo = test.val : '';
                        }
                      )
                    } catch (error) {
                      tempo = 0
                    }

                    answer = {
                      question_id: itemPertanyaan.id,
                      data: tempo,
                      urutan : indexPertanyaan.urutan
                    }
                  }

                  context.state.answer[indexGroup].splice(indexPertanyaan, 0, answer)
                  context.state.sum[indexGroup] += tempo

                }
              })

            });

            context.state.loadingCardQuestion = false
            resolve(response)
          }).catch(error => {
            context.state.loadingCardQuestion = false
            reject(error)
          })
        })
      },
      addAnswer(context, payload) {
        if (!context.state.viewOnly) {
          const answer = context.state.answer
          const sum = context.state.sum
          let temp=0;
          if (payload.accordion) {
            answer[payload.group][payload.accordionData].splice(payload.question, 1, payload.answer)
            for (let index = 0; index < answer[payload.group].length; index++) {
                temp += answer[payload.group][index].reduce((partialSum, a) => partialSum + a, 0);
            }
            sum[payload.group] = temp
          }else{
            answer[payload.group].splice(payload.question, 1, {
              question_id : payload.id,
              data: payload.answer})
            sum[payload.group] = answer[payload.group].reduce((partialSum, a) => partialSum + a.data, 0);
          }
          context.commit('ADD_ANSWER', answer)
        }
      },
      initAnswer(context, payload){
        const a = context.state.answer
        const s = context.state.sum
        for (let index = 0; index < payload.length; index++) {
          a.push([])
          s.push(0)
          if (payload[index].accordion) {
            for (let i = 0; i < payload[index].data.length; i++) {
                a[index].push([])
                for (let y = 0; y < payload[index].data[i].questions.length; y++) {
                  
                  a[index][i].push({
                    question_id: payload[index].data[i].id,
                    data : 0,
                    urutan : y + 1
                  })
                  s[index] += 1
                }
            }
          }
          else{
            for (let x = 0; x < payload[index].data.length; x++) {
              if( payload[index].data[x].rumus == null){ 
                a[index].push({
                  question_id: payload[index].data[x].id,
                  data: 0,
                  urutan : x + 1
                })
              }
              else{
                let tempo = 0 
                try {
                  payload[index].data[x].autofill.map(
                    test => {
                      eval(test.req.replace(new RegExp('{'+ 'var' +'}','g'), eval(payload[index].data[x].rumus))) ? tempo = test.val : '';
                    }
                  )
                } catch (error) {
                  tempo = 0
                }

                a[index].push({
                  question_id: payload[index].data[x].id,
                  data: tempo,
                  urutan : x + 1
                })

                s[index] += tempo
              }
            }
          }
        }
      }
    },
    getters: {
      token: (state) => () => {
        return state.token
      },
      answerList: (state) => (payload) => {
        return `${state.answer[payload]}`
      },
      answerStatus: (state) => (payload) => {
        if (payload.accordion) {
          return `${state.answer[payload.group][payload.accordionData][payload.list].data}`  
        }
        return `${state.answer[payload.group][payload.list]}`
      },
      sumGet: (state) => (payload) => {
        // console.log(payload)
        return `${state.sum[payload-1]}`
      },
      permohonanGet: (state) => () => {
        const result = JSON.stringify(state.dropdown)
        // console.log(result)
        return result
      },
      profiling: (state) => () => {
        const result = state.activeProfiling
        return JSON.stringify(result);
      }
  },
  modules: {
    MasterMitra,
    ScoringModule,
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    ProfilingModule,
    EditProfilingModule,
    FilterPermohonanModule,
    KeuanganModule,
    CmsModule,
    LoggedUser,
    SlikModule,
    Layout,
    MasterIjp
  },
});

export default store;

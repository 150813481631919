import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";
import { ElMessage } from 'element-plus';


export default {
  namespaced: true,

  state: {
    autofillInput: "",
    listOptions: [],
    variantInput: 'default',
    variantList: [],
    pertanyaanForms: {
      id_pertanyaan: "",
      isi_pertanyaan : "",
      id_group_pertanyaan : 0,
      id_jenis_kredit : 0,
      urutan : 0
    },
    listJenis: [],
    listGroup: [],
    rumusTemp : [
    ],
    activeListPertanyaan: true,
    listPertanyaan: [],
    submitLoading: false,
    dataEdit: [],
    activeEditPertanyaan: true,
    dataTambah: {
      urutan: '',
      isi_pertanyaan: '',
      variant: 'default',
      autofill:'',
      rumus: [],
      variant_list: [],
      akun_keuangan: []
    },
    keuanganAkuns: {
      "symbol": [
        {
          value : "(",
          label : "(",
          type : "symbol"
        },
        {
          value : ")",
          label : ")",
          type : "symbol"
        },
        {
          value : "*",
          label : "*",
          type : "symbol"
        },
        {
          value : "/",
          label : "/",
          type : "symbol"
        },
        {
          value : "-",
          label : "-",
          type : "symbol"
        },
        {
          value : "+",
          label : "+",
          type : "symbol"
        },
        
        {
          value : "*100",
          label : "*100%",
          type : "symbol"
        }
      ]
    },
    typesAkun: ['symbol', 'column','column0', 'sum', 'sum0', 'sumG', 'sumG0', 'atmr'],
    keuanganC0: [],
    keuanganC: []
  },

  getters: {
  },

  mutations: {
    RESET(state) {
      state.rumusTemp = []
      state.pertanyaanForms.isi_pertanyaan = ""
      state.pertanyaanForms.urutan = 0
    },
    RESET_TAMBAH_PERTANYAAN(state) {
      state.dataTambah ={
        urutan: '',
        isi_pertanyaan: '',
        variant: 'default',
        autofill:'',
        rumus: [],
        variant_list: [],
        akun_keuangan: []
      }
    },
    RESET_LIST_GRUP(state) {
      state.listGroup = []
    },
    RESET_LIST_PERTANYAAN (state) {
      state.listPertanyaan = []
    },
    RESET_DATA_EDIT(state) {
      state.dataEdit = []
    },
    SET_ACTIVE_LIST_PERTANYAAN(state,n) {
      state.activeListPertanyaan = n
    },
    SET_ACTIVE_EDIT_PERTANYAAN(state,n) {
      state.activeEditPertanyaan = n
    },
    SET_UPDATE_OPTION(state,n) {
      state.dataEdit.option = n
    },
    SET_DATA_ATTRIBUTE_PERTANYAAN(state, n) {
      state.dataTambah.urutan = n.urutan
      state.dataTambah.variant_list = n.variant_list
      state.dataTambah.akun_keuangan = n.akun_keuangan
    },
    MERGE_AKUN_KEUANGAN(state, akuns) {
      Object.assign(state.keuanganAkuns, akuns)
    },
    SET_RUMUSS(state,n) {
      state.rumusTemp = n == null ? [] : JSON.parse(n)
    },
    SET_KEUANGANC0(state,n) {
      state.keuanganC0 = n
    },
    SET_KEUANGANC(state,n) {
      state.keuanganC = n
    },
    SET_LIST_OPSI (state, n) {
      state.listOptions = n
    },
    SET_LIST_JENIS (state, n) {
      state.listJenis = n
    },
    SET_LIST_GROUP (state, n) {
      state.listGroup = n
    },
    SET_LIST_PERTANYAAN (state, n) {
      state.listPertanyaan = n
    },
    SET_DATA_EDIT(state, n) {
      state.dataEdit = n
    },
    ACTIVE_G (state, n) {
      state.pertanyaanForms.id_group_pertanyaan = n
    },
    ACTIVE_J (state, n) {
      state.pertanyaanForms.id_jenis_kredit = n
    },
    
    FORM_URUT (state, n) {
      state.pertanyaanForms.urutan = n
    },
    FORM_ISI (state, n) {
      state.pertanyaanForms.isi_pertanyaan = n
    },
    FORM_ID (state, n) {
      state.pertanyaanForms.id_pertanyaan = n
    },
    
    S_LOADING (state) {
      state.submitLoading = true
    },
    S_COMP (state) {
      state.submitLoading = false
    },
    SET_DROP_AKUN (state, n) {
      state.keuanganAkuns = n
    },
    REMOVE_DATA_RUMUS(state, data){
      data.array.splice(data.index, 1)
    }
  },

  actions: {
    async initialize(context){
      const variants = await axios.get(process.env.VUE_APP_API_SP3 + "scoring/variants",{params: {
        id: context.state.pertanyaanForms.id_jenis_kredit
      }})
      context.state.variantList = variants.data.data;
    },
    resetForm(context){
      context.commit('RESET')
    },
    async getAkuns(context) {
      let temp =  [
        {
          value : "(",
          label : "(",
          type : "symbol"
        },
        {
          value : ")",
          label : ")",
          type : "symbol"
        },
        
        {
          value : "100%",
          label : "100%",
          type : "symbol"
        },
        {
          value : "*",
          label : "*",
          type : "symbol"
        },
        {
          value : "/",
          label : "/",
          type : "symbol"
        },
        {
          value : "-",
          label : "-",
          type : "symbol"
        },
        {
          value : "+",
          label : "+",
          type : "symbol"
        }
      ];
      await axios.get(process.env.VUE_APP_API_SP3 + "pertanyaan/drop-akun")
      .then(response => {
        // temp.concat(response.data.data)
      })
      .then(() => {
        context.commit('SET_DROP_AKUN', temp)
      });
    },
    async getDropdown(context){
      await axios.get(process.env.VUE_APP_API_SP3 + "keuangan/akun",{params: {
        skema: context.state.pertanyaanForms.id_jenis_kredit
      }})
              .then(response => {
                context.commit('SET_KEUANGANC0', response.data.data) 
                context.commit('SET_KEUANGANC', response.data.data)})
    },
    massUpdate(context, pay){
      let i = 0;
      let temp =[];
      context.state.listOptions.map(data => {
        if (pay[i] != undefined && pay[i] != '') {
          if (pay[i].includes('.')) {
            temp = pay[i].split(/\./)

            temp[0] = parseInt(temp[0]) 
            data.bobot_nilai = isNaN(temp[0]) ? 0 : temp[0];
            data.keterangan_opsi = temp[1];
            context.dispatch('bobotUpdate', {
              id: data.id_opsi,
              new: isNaN(temp[0]) ? 0 : temp[0]
            });
            context.dispatch('ketUpdate', {
              id: data.id_opsi,
              new: temp[1]
            });
          } else {
            data.keterangan_opsi = pay[i];
            context.dispatch('ketUpdate', {
              id: data.id_opsi,
              new: pay[i]
            })
          }
          

          
          i++;
        }
      })
    },
    addItemRumus(context, typeForm) {
      context.state[typeForm].rumus.push({
        item: {
          value : "(",
          label : "(",
          type : "symbol"
        },
        show: false,
        type : "symbol"
      })
    },
    activeGroup(context, id){
      context.commit('ACTIVE_G', id)
    },
    activeJenis(context, id){
      context.commit('ACTIVE_J', id)
    },
    urutanForm(context, urut){
      context.commit('FORM_URUT', urut)
    },
    isiForm(context, isi){
      context.commit('FORM_ISI', isi)
    },
    async getFormPertanyaan(context, id_pertanyaan){
      await axios.get(process.env.VUE_APP_API_SP3 + "pertanyaan", {
        params: {
          id: id_pertanyaan
        }
      })
      .then(response => {
        context.commit('SET_ACTIVE_EDIT_PERTANYAAN', true)
        context.commit('SET_DATA_EDIT', response.data.data)
        context.commit('MERGE_AKUN_KEUANGAN', response.data.data.keuanganAkuns)
      }).catch((error) => {
        context.commit('SET_ACTIVE_EDIT_PERTANYAAN', false)
        ElMessage({
          showClose: true,
          message: JSON.parse(error.request.response).message != null ? JSON.parse(error.request.response).message + '!, silahkan coba lagi!' : 'Terjadi kesalahan server!, silahkan coba lagi!',
          type: error.request.status == 500 ? 'error' : 'warning',
        })
      })
      // .then(() => context.commit('SET_LIST_OPSI', jenis)).then(()=>{router.push('/wizard4')});

      // SET_DATA_EDIT


      // context.commit('FORM_URUT', form.urut)
      // context.commit('FORM_ISI', form.isi)
      // context.commit('FORM_ID', form.id)
      // context.state.autofillInput = form.autofill
      // let jenis = [];
      // await axios.get(process.env.VUE_APP_API_SP3 + "option/read",{params: {
      //     id: context.state.pertanyaanForms.id_pertanyaan
      //   }},
      //    )
      //           .then(response => {
      //             context.commit('SET_RUMUSS', response.data.rumus.rumus)
      //             jenis = response.data.data})
      //           .then(() => context.commit('SET_LIST_OPSI', jenis)).then(()=>{router.push('/wizard4')});
    },
    async pertanyaanSubmit(context, payload){
      await axios.post(process.env.VUE_APP_API_SP3 + "pertanyaan/create", {data: payload})
      .then((res) => {
        context.dispatch('pertanyaanGet', {
          group: payload.id_group_pertanyaan,
          jenis: payload.id_jenis_kredit
        });
        ElMessage({
            showClose: true,
            message: 'Berhasil Diubah!',
            type: 'success',
        })
      }).catch((error) => {
        ElMessage({
          showClose: true,
          message: JSON.parse(error.request.response).message != null ? JSON.parse(error.request.response).message + '!, silahkan coba lagi!' : 'Terjadi kesalahan server!, silahkan coba lagi!',
          type: error.request.status == 500 ? 'error' : 'warning',
        })
      })
    },
    async getAtributPertanyaan(context, params) {
      await axios.get(process.env.VUE_APP_API_SP3 + "pertanyaan/attribute", {
        params: params
      })
      .then((res) => {
        context.commit('SET_DATA_ATTRIBUTE_PERTANYAAN', res.data.data)
      }).catch((error) => {
        ElMessage({
          showClose: true,
          message: 'Terjadi kesalahan saat mengambil data, silahkan coba lagi!',
          type: 'error',
        })
      })
    },
    async editFormPertanyaan(context) {
      let body = {
        id_pertanyaan: context.state.dataEdit.id_pertanyaan,
        urutan: context.state.dataEdit.urutan,
        isi_pertanyaan: context.state.dataEdit.isi_pertanyaan,
        variant: context.state.dataEdit.variant,
        rumus: context.state.dataEdit.rumus,
        autofill: context.state.dataEdit.autofill,
      }
      await axios.put(process.env.VUE_APP_API_SP3 + "pertanyaan", {data: body})
        .then((res) => {
          ElMessage({
              showClose: true,
              message: 'Berhasil Diubah!',
              type: 'success',
          })
        }).catch((error) => {
          ElMessage({
            showClose: true,
            message: JSON.parse(error.request.response).message != null ? JSON.parse(error.request.response).message + '!, silahkan coba lagi!' : 'Terjadi kesalahan server!, silahkan coba lagi!',
            type: error.request.status == 500 ? 'error' : 'warning',
          })
        })
    },

    async addOpsi(context, payload){
      await axios.post(process.env.VUE_APP_API_SP3 + "option/create", {data: payload})
        .then((res) => {
          let data = res.data.data
          context.commit('SET_UPDATE_OPTION', data)
          ElMessage({
              showClose: true,
              message: 'Berhasil Diubah!',
              type: 'success',
          })
        }).catch((error) => {
          ElMessage({
            showClose: true,
            message: JSON.parse(error.request.response).message != null ? JSON.parse(error.request.response).message + '!, silahkan coba lagi!' : 'Terjadi kesalahan server!, silahkan coba lagi!',
            type: error.request.status == 500 ? 'error' : 'warning',
          })
        })
    },
    async deleteOpsi(context, payload){
      await axios.get(process.env.VUE_APP_API_SP3 + "option/delete", {params: {
        id_opsi: payload.id_opsi,
        id_pertanyaan: payload.id_pertanyaan
      }})
        .then((res) => {
          let data = res.data.data
          context.commit('SET_UPDATE_OPTION', data)
          ElMessage({
              showClose: true,
              message: 'Berhasil Dihapus!',
              type: 'success',
          })
        }).catch((error) => {
          ElMessage({
            showClose: true,
            message: JSON.parse(error.request.response).message != null ? JSON.parse(error.request.response).message + '!, silahkan coba lagi!' : 'Terjadi kesalahan server!, silahkan coba lagi!',
            type: error.request.status == 500 ? 'error' : 'warning',
          })
        })
    },
    async pertanyaanGet(context, ids){
        let jenis = [];
        await axios.get(process.env.VUE_APP_API_SP3 + "pertanyaan/read-pertanyaan",{params: {
            group: ids.group,
            jenis: ids.jenis
          }})
          .then((response) => {
            context.commit('SET_ACTIVE_LIST_PERTANYAAN', true)
            context.commit('SET_LIST_PERTANYAAN', response.data.data.pertanyaan)
            context.commit('MERGE_AKUN_KEUANGAN', response.data.data.keuanganAkuns)
            context.commit('ACTIVE_G', ids.group)
            context.commit('ACTIVE_J', ids.jenis)
          }).catch((error) => {
            context.commit('SET_ACTIVE_LIST_PERTANYAAN', false)
            ElMessage({
              showClose: true,
              message: JSON.parse(error.request.response).message != null ? JSON.parse(error.request.response).message + '!, silahkan coba lagi!' : 'Terjadi kesalahan server!, silahkan coba lagi!',
              type: error.request.status == 500 ? 'error' : 'warning',
            })
          })
      }, 

    async jenisGet(context, {search, token}){
      return new Promise( async (resolve, reject) => {
        await axios.get(process.env.VUE_APP_API_SP3 + "pertanyaan/read-jenis", {
          params: {
            search: search
          },
          cancelToken: token
        }).then(response => {
          context.commit('SET_LIST_JENIS', response.data.data)
          resolve(response)
        }).catch(error => {
          if(axios.isCancel(error)){
            return;
          } else {
            reject(error)
          }
        })
      })
    }, 
    async groupGet(context, id){
        let jenis = [];
        await axios.get(process.env.VUE_APP_API_SP3 + "pertanyaan/read-group",{params: {
            id: id
          }})
                   .then(response => {jenis = response.data.data})
                   .then(() => context.commit('SET_LIST_GROUP', jenis));
      }, 
    async opsiGet(context){
      let jenis = [];
      await axios.get(process.env.VUE_APP_API_SP3 + "option/read",{params: {
          id: context.state.pertanyaanForms.id_pertanyaan
        }},
         )
                .then(response => {jenis = response.data.data})
                .then(() => context.commit('SET_LIST_OPSI', jenis));
    }, 
    async bobotUpdate(context, payload){
        await axios.put(process.env.VUE_APP_API_SP3 + "option/update-bobot",{
          id: payload.id,
          new: payload.new
        },
        ).then(res => console.log(res))
    }, 
    async ketUpdate(context, payload){
      await axios.put(process.env.VUE_APP_API_SP3 + "option/update-ket",{
          id: payload.id,
          new: payload.new
        },
         ).then(res => console.log(res))
    }, 
    async descUpdate(context, payload){
      await axios.put(process.env.VUE_APP_API_SP3 + "option/update-desc",{
          id: payload.id,
          new: payload.new
        },
         ).then(res => console.log(res))
    }, 
    async pertanyaanUpdate(context){
      context.commit('S_LOADING')
      let jenis;
      await axios.put(process.env.VUE_APP_API_SP3 + "pertanyaan/update", {...context.state.pertanyaanForms, autofill: context.state.autofillInput, variant: context.state.variantInput , rumus: context.state.rumusTemp != null ? context.state.rumusTemp.length > 0 ? JSON.stringify(context.state.rumusTemp) : null : null})
      .then((res) => {
        context.commit('S_COMP')
        Swal.fire({
          text: "All is cool! Now you submit this form",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        }).then(() => {
          axios.get(process.env.VUE_APP_API_SP3 + "pertanyaan/read-pertanyaan",{params: {
            group: context.state.pertanyaanForms.id_group_pertanyaan,
            jenis: context.state.pertanyaanForms.id_jenis_kredit 
          }})
            .then(response => {jenis = response.data.data})
            .then(() => context.commit('SET_LIST_PERTANYAAN', jenis))
        });
      })
    },
    deletePertanyaan(context, data) {
      return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_API_SP3 + "pertanyaan", data)
        .then(res => {
          context.commit('SET_LIST_PERTANYAAN', res.data.data)
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
      })
    }
  },
}
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";


export default {
  namespaced: true,

  state: {
    loading: false,
    pagination: {},
    detail: {},
    dhn: false,
    loading : false,
  },

  getters: {
  },

  mutations: {
    SET_LIST_SCORING (state, n) {
      state.detail = {}
      state.pagination = n
    },
    SET_DETAIL (state, n){
      state.detail = n
    },
    SET_LOADING (state) {
      state.loading = true
    },
    SET_FINISHED (state) {
      state.loading = false
    }
  },

  actions: {
    async getScoringList(context, payload){
      this.state.pagination = {};
      context.commit("SET_LOADING")
      await axios.get(payload.link == null ? process.env.VUE_APP_API_SP3 + "scoring/getList" : payload.link,{params: {
        entry: payload.entry,
        page: payload.page,
        q: payload.q
      }}).then(
        res => context.commit("SET_LIST_SCORING", res.data.data)
      ).then(() => context.commit("SET_FINISHED"))
      .catch(err => {
        context.commit("SET_FINISHED")
        Swal.fire({
          text: err,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Coba lagi",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        })
      })
    },
    async detail(context, payload){
      context.state.loading = true
      await axios.get(process.env.VUE_APP_API_SP3 + "scoring/resume",{params: {
        id: payload
      }}).then(
        res => {
          context.state.dhn = res.data.dhn
          context.commit("SET_DETAIL", res.data.data)}
      ).then(() => {
          context.state.loading = false
      })
      .catch(err => {
        context.state.loading = false
        Swal.fire({
          text: err,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Coba lagi",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        })
      })
    }
  },
  
  
  
}
export default {
    namespaced: true,
  
    state: {
        data: []
    },
  
    getters: {
    },
  
    mutations: {
        setStatus(state, {key,val}){
            state[key] = val
        }
    },
  
    actions: {
        
    },  
  }
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/Builder.vue"),
      },
      {
        path: "/teknik/permohonan",
        name: "Daftar Permohonan",
        component: () => import("@/views/tehnik/Permohonan.vue"),
        meta:{KOMITE_ACCESS:true}
      },
      {
        path: "/komite/permohonan",
        name: "Komite Permohonan",
        component: () => import("@/views/tehnik/Permohonan.vue"),
        meta:{KOMITE_ACCESS:true}
      },
      {
        path: "/marketing/teknik/sp3",
        name: "penerbitan SP3",
        component: () => import("@/views/tehnik/SP3.vue"),
        meta:{KOMITE_ACCESS:true}
      },
      {
        path: "/marketing/teknik/komite",
        name: "Hasil Penilaian Komite",
        component: () => import("@/views/tehnik/Komite.vue"),
        meta:{KOMITE_ACCESS:true}
      },
      {
        path: "/komite/teknik/sp3",
        name: "Komite SP3",
        component: () => import("@/views/tehnik/SP3.vue"),
        meta:{KOMITE_ACCESS:true}
      },
      {
        path: "/komite/teknik/komite",
        name: "Penilaian Komite",
        component: () => import("@/views/tehnik/Komite.vue"),
        meta:{KOMITE_ACCESS:true}
      },
      {
        path: "/teknik/profiler",
        name: "Pengisian Profiling",
        component: () => import("@/views/profiling/Profiler.vue"),
        meta:{SCORING_ACCESS:true}
      },
      {
        path: "/marketing/profiler",
        name: "Isi Profiling",
        component: () => import("@/views/profiling/Profiler.vue"),
        meta:{SCORING_ACCESS:true}
      },
      {
        path: "/teknik/permohonan-detail/:id",
        name: "Detail Permohonan",
        component: () => import("@/views/marketing/PermohonanDetail.vue"),
        meta:{SCORING_ACCESS:true}
      },
      {
        path: "/marketing/keuangan/:id",
        name: "keuangan",
        component: () => import("@/views/marketing/Keuangan.vue"),
        meta:{SCORING_ACCESS:true}
      },
      {
        path: "/marketing/keuangan/manual",
        name: "keuangan",
        component: () => import("@/views/marketing/Keuangan.vue"),
        meta:{SCORING_ACCESS:true}
      },
      {
        path: "/marketing/all/profiler",
        name: "Daftar Profiling",
        component: () => import("@/views/profiling/Index.vue"),
        meta:{SCORING_ACCESS:true}
      },
      // {
      //   path: "/cms/keuangan",
      //   name: "keuangan-cms",
      //   component: () => import("@/views/cms/keuangan.vue"),
      // },
      {
        path: "/profiler/:id",
        name: "Edit Profiling",
        component: () => import("@/views/profiling/EditTmp.vue"),
        meta:{SCORING_ACCESS:true}
      },
      {
        path: "/cms/sp-3/templating",
        name: "Master Surat",
        component: () => import("@/views/cms/Ckeditor.vue"),
        meta:{CMS_ACCESS:true}
      },
      {
        path: "cms/ckeditor",
        name: "Master Surat",
        component: () => import("@/views/cms/Ckeditor.vue"),
        meta:{CMS_ACCESS:true}
      },
      {
        path: "/cms/staff",
        name: "Master Staff",
        component: () => import("@/views/cms/StaffCMS.vue"),
        meta:{CMS_ACCESS:true}
      },
      {
        path: "/cms/jabatan",
        name: "Master Jabatan",
        component: () => import("@/views/cms/JabatanCMS.vue"),
        meta:{CMS_ACCESS:true}
      },
      {
        path: "/cms/kelengkapan",
        name: "Master Kelengkapan Dokumen",
        component: () => import("@/views/cms/kelengkapan/JenisKredit.vue"),
      },
      {
        path: "/cms/master-ijp",
        name: "Master IJP",
        component: () => import("@/views/cms/master-ijp/groupIjp.vue"),
      },
      {
        path: "/cms/master-ijp/tambah",
        name: "Tambah Master IJP",
        component: () => import("@/views/cms/master-ijp/tambahGroupIjp.vue"),
      },
      {
        path: "/cms/master-ijp/edit/:id_group_ijp",
        name: "Edit Master IJP",
        component: () => import("@/views/cms/master-ijp/EditGroupIjp.vue"),
      },
      {
        path: "/cms/master-pekerjaan",
        name: "Master Pekerjaan",
        component: () => import("@/views/cms/master-pekerjaan/Index.vue"),
      },
      // {
      //   path: "/sp3-mail",
      //   name:"sp3-mail",
      //   component: ()=>import("@/views/sp3-mail/MailView.vue")
      // },
      // {
      //   path: "/crafted/modals/forms/new-target",
      //   name: "modals-forms-new-target",
      //   component: () => import("@/views/crafted/modals/forms/KonfirmasiModalToggle.vue"),
      // },
      // {
      //   path: "/crafted/modals/forms/new-card",
      //   name: "modals-forms-new-card",
      //   component: () => import("@/views/crafted/modals/forms/NewCard.vue"),
      // },
      // {
      //   path: "/crafted/modals/forms/new-address",
      //   name: "modals-forms-new-address",
      //   component: () => import("@/views/crafted/modals/forms/NewAddress.vue"),
      // },
      // {
      //   path: "/crafted/modals/forms/create-api-key",
      //   name: "modals-forms-create-api-key",
      //   component: () =>
      //     import("@/views/crafted/modals/forms/CreateApiKey.vue"),
      // },
      // {
      //   path: "/crafted/modals/wizards/two-factor-auth",
      //   name: "modals-wizards-two-factor-auth",
      //   component: () =>
      //     import("@/views/crafted/modals/wizards/TwoFactorAuth.vue"),
      // },
      // {
      //   path: "/crafted/modals/wizards/create-app",
      //   name: "modals-wizards-create-app",
      //   component: () => import("@/views/crafted/modals/wizards/CreateApp.vue"),
      // },
      {
        path: "/crafted/modals/wizards/create-account",
        name: "modals-wizards-create-account",
        component: () =>
          import("@/views/crafted/modals/wizards/CreateAccount.vue"),
      },
      // {
      //   path: "/list-scoring",
      //   name: "listScore",
      //   component: () => import("@/views/Scoring.vue"),
      //   // meta:{SCORING_ACCESS:true}
      // },
      {
        path: "/scoring/list-scoring",
        name: "Daftar Scoring",
        component: () => import("@/views/Scoring.vue"),
        meta:{SCORING_ACCESS:true}
      },
      {
        path: "/scoring/resume/:scoring",
        name: "resume",
        component: () => import("@/views/scoring/Resume.vue"),
        meta:{SCORING_ACCESS:true}
      },
      {
        path: "/scoring/evaluasi/:no",
        name: "Evaluasi",
        component: () => import("@/views/scoring/evaluasi/Quiz.vue"),
        meta:{SCORING_ACCESS:true}
      },
      
      {
        path: "/scoring/add-scoring",
        name: "Tambah Scoring",
        component: () => import("@/views/scoring/evaluasi/Quiz.vue"),
        meta:{SCORING_ACCESS:true}
      },
      {
        path: "/cms/content-management-system",
        name: "Master Scoring",
        component: () => import("@/views/cms/JenisList.vue"),
        meta:{CMS_ACCESS:true}
      },
      {
        path: "/jenis-kredit/:id",
        name: "group",
        component: () => import("@/views/cms/GroupList.vue"),
        meta:{CMS_ACCESS:true}
      },
      
      {
        path: "/group/:group/:jenis",
        name: "list pertanyaan",
        component: () => import("@/views/cms/PertanyaanList.vue"),
        meta:{CMS_ACCESS:true}
      },
      // {
      //   path: '/pertanyaan-jenis-kredit',
      //   name: 'Buat Pertanyaan',
      //   component: () => import('@/views/cms/PertanyaanCreate.vue'),
      //   meta:{CMS_ACCESS:true}
      // },
      {
        path: '/edit-pertanyaan/:id',
        name: 'Edit Pertanyaan',
        component: () => import('@/views/cms/EditPertanyaan.vue'),
        meta:{CMS_ACCESS:true}
      },
      {
        path: '/cms/master-mitra',
        name: 'Master Mitra',
        component: () => import('@/views/cms/MasterMitra.vue'),
        meta:{CMS_ACCESS:true}
      },
      {
        path: "/error-server",
        name: "ErrorServer",
        component: () => import("../components/ErrorServer.vue"),
        meta:{ERROR_PAGE_ACCESS:true}
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  // {
  //   path: "/multi-step-sign-up",
  //   name: "multi-step-sign-up",
  //   component: () =>
  //     import("@/views/crafted/authentication/MultiStepSignUp.vue"),
  // },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// const arr2 = [{id: 1, name: 'Tom'}];

// const getDifference = (array1, array2) => {
//   return array1.filter(object1 => {
//     return !array2.some(object2 => {
//       return object1.module_id === object2.module_id;
//     });
//   });
// }

router.beforeEach((to, from) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });


  // if (to.name !== 'sign-in'  && store.state.LoggedUser.user.permission.length < 1) {
  //   return{
  //     path: '/sign-in'
  //   }
  // }

  if (to.meta.SCORING_ACCESS && store.state.LoggedUser.user.permission.filter(e => e.module_id != '72321133-f0c9-4c1e-90d7-6b838bd54000').length < 1) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    // return {
    //   path: '/dashboard',
    // }
  }

  if (to.meta.ERROR_PAGE_ACCESS && store.state.Layout.detectErrorServer == 0) {
    router.push('/dashboard')
  }

  store.commit('Layout/setDetectErrorServer', 0)

  if (to.meta.CMS_ACCESS && store.state.LoggedUser.user.permission.filter(e => e.module_id != '60e8b26c-aa58-4ed2-a0c4-ff4141aa9bc2').length < 1) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    // return {
    //   path: '/dashboard',
    // }
  }

  if (to.meta.KOMITE_ACCESS && store.state.LoggedUser.user.permission.filter(e => e.module_id != 'd605ab99-c71f-49a5-83fc-f7121384bbdc').length < 1) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    // return {
    //   path: '/dashboard',
    // }
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;


import axios from "axios";

export default {

  namespaced: true,

  state: {
    groupList: [],
    dataGroup: [],
    listJenisKredit: [],
    listMitra: [],
    listPekerjaan: [],
    formInsert: {
      nama_group: null,
      deskripsi_group: null,
      jenis_kredit: [],
      ijp: []
    },
    tambahJenisKredit: {
      id_jenis_kredit: null,
      nama_jenis_kredit: null,
      id_mitra: null,
      nama_mitra: null,
      kode_pekerjaan: null,
      nama_pekerjaan: null,
      ketentuan_mitra: null
    },
    tambahTarifIJP: {
      jw_mulai: null,
      jw_akhir: null,
      tarif_ijp: null
    },
    loadingSelect: {
      jenis_kredit: false,
      mitra: false,
      pekerjaan: false
    }
  },

  getters: {
  },

  mutations: {
    SET_GROUP_LIST(state, value){
      state.groupList = value
    },
    SET_DATA_GROUP(state, value){
      state.dataGroup = value
    },
    SET_LIST_JENIS_KREDIT(state, value){
      state.listJenisKredit = value
    },
    SET_LIST_MITRA(state, value) {
      state.listMitra = value
    },
    SET_LIST_PEKERJAAN(state, value) {
      state.listPekerjaan = value
    },
    SET_DATA_AFTER_SELECT_JENIS_KREDIT(state, data) {
      state.tambahJenisKredit.nama_jenis_kredit = data.nama_jenis_kredit
      state.tambahJenisKredit.ketentuan_mitra = data.ketentuan_mitra
      if(data.ketentuan_mitra == 'specific'){
        state.tambahJenisKredit.id_mitra = null
        state.tambahJenisKredit.nama_mitra = null
        state.tambahJenisKredit.kode_pekerjaan = null
        state.tambahJenisKredit.nama_pekerjaan = null
      } 
      else {
        state.tambahJenisKredit.id_mitra = 'default'
        state.tambahJenisKredit.nama_mitra = 'default'
        state.tambahJenisKredit.kode_pekerjaan = 'default'
        state.tambahJenisKredit.nama_pekerjaan = 'default'
      }
    },
    SET_DATA_AFTER_SELECT_MITRA(state, data) {
      state.tambahJenisKredit.id_mitra = data.id_mitra
      state.tambahJenisKredit.nama_mitra = data.nama_mitra
      state.tambahJenisKredit.kode_pekerjaan = null
      state.tambahJenisKredit.nama_pekerjaan = null
    },
    SET_DATA_AFTER_SELECT_PEKERJAAN(state, data) {
      state.tambahJenisKredit.kode_pekerjaan = data.kode_pekerjaan
      state.tambahJenisKredit.nama_pekerjaan = data.nama_pekerjaan
    },
    RESET_DATA_FORM_TAMBAH_JENIS_KREDIT(state) {
      state.tambahJenisKredit.id_jenis_kredit = null
      state.tambahJenisKredit.nama_jenis_kredit = null
      state.tambahJenisKredit.id_mitra = null
      state.tambahJenisKredit.nama_mitra = null
      state.tambahJenisKredit.kode_pekerjaan = null
      state.tambahJenisKredit.nama_pekerjaan = null
    },
    RESET_DATA_FORM_TAMBAH_MITRA(state) {
      state.tambahJenisKredit.id_mitra = null
      state.tambahJenisKredit.nama_mitra = null
      state.tambahJenisKredit.kode_pekerjaan = null
      state.tambahJenisKredit.nama_pekerjaan = null
    },
    RESET_DATA_FORM_TAMBAH_PEKERJAAN(state) {
      state.tambahJenisKredit.kode_pekerjaan = null
      state.tambahJenisKredit.nama_pekerjaan = null
    },
    RESET_DATA_FORM_TAMBAH_IJP(state) {
      state.tambahTarifIJP = {
        jw_mulai: null,
        jw_akhir: null,
        tarif_ijp: null
      }
    },
    RESET_DATA_FORM(state) {
      state.formInsert = {
        nama_group: null,
        deskripsi_group: null,
        jenis_kredit: [],
        ijp: []
      }
    },
    RESET_STATE(state) {
      state.groupList ==[]
      state.dataGroup = []
      state.listJenisKredit = []
      state.listMitra = []
      state.formInsert = {
        nama_group: null,
        deskripsi_group: null,
        jenis_kredit: [],
        ijp: []
      }
      state.tambahJenisKredit = {
        id_jenis_kredit: null,
        nama_jenis_kredit: null,
        id_mitra: null,
        nama_mitra: null,
        kode_pekerjaan: null,
        nama_pekerjaan: null
      }
      state.tambahTarifIJP = {
        jw_mulai: null,
        jw_akhir: null,
        tarif_ijp: null
      }
    }
  },

  actions: {
    getGroupList(context, {search, token}) {
      return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_API_SP3 + "master-ijp/group/list", {
          params: {
            search: search
          },
          cancelToken: token
        })
        .then(res => {
          context.commit('SET_GROUP_LIST', res.data.data)
          resolve(res)
        })
        .catch(error => {
          if(axios.isCancel(error)){
            return;
          } else {
            reject(error)
          }
        })
      })
    },
    getDataGroup(context, data){
      return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_API_SP3 + "master-ijp/group", { 
            params: {
              id_group_ijp: data
            }
        })
        .then(res => {
          context.commit('SET_DATA_GROUP', res.data.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error);
        })
      })
    },
    getSelectJenisKredit(context, data) {
      return new Promise( async (resolve, reject) => {
        context.state.loadingSelect.jenis_kredit = true

        await axios.get(process.env.VUE_APP_API_SP3 + "master-ijp/jenis-kredit/select", {
          params: {
            search: data.search
          },
          cancelToken: data.token
        })
        .then(res => {
          context.state.loadingSelect.jenis_kredit = false
          resolve(res)
        })
        .catch((error) => {
          context.state.loadingSelect.jenis_kredit = false
          if (axios.isCancel(error)) {
            return 0
          } else {
            console.log(error)
          }
          reject(error)
        })
      })
    },
    getSelectMitra(context, data) {
      return new Promise( async (resolve, reject) => {
        context.state.loadingSelect.mitra = true

        await axios.get(process.env.VUE_APP_API_SP3 + "master-ijp/mitra/select", {
          params: {
            search: data.search,
            id_jenis_kredit: context.state.tambahJenisKredit.id_jenis_kredit
          },
          cancelToken: data.token
        })
        .then(res => {
          context.state.loadingSelect.mitra = false
          resolve(res)
        })
        .catch((error) => {
          context.state.loadingSelect.mitra = false
          if (axios.isCancel(error)) {
            return 0
          } else {
            console.log(error)
          }
          reject(error)
        })
      })
    },
    getSelectPekerjaan(context, data) {
      return new Promise( async (resolve, reject) => {
        context.state.loadingSelect.pekerjaan = true

        await axios.get(process.env.VUE_APP_API_SP3 + "master-ijp/pekerjaan/select", {
          params: {
            search: data.search,
            id_jenis_kredit: context.state.tambahJenisKredit.id_jenis_kredit,
            id_mitra: context.state.tambahJenisKredit.id_mitra
          },
          cancelToken: data.token
        })
        .then(res => {
          context.state.loadingSelect.pekerjaan = false
          resolve(res)
        })
        .catch((error) => {
          context.state.loadingSelect.pekerjaan = false
          if (axios.isCancel(error)) {
            return 0
          } else {
            console.log(error)
          }
          reject(error)
        })
      })
    },
    postGroup(context, data) {
      return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_API_SP3 + "master-ijp/group", {
          data: data
        })
        .then(res => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },
    updateGroup(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(process.env.VUE_APP_API_SP3 + "master-ijp/group", data)
        .then(res => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },
    deleteGroup(context, id) {
      return new Promise((resolve, reject) => {
        axios.delete(process.env.VUE_APP_API_SP3 + "master-ijp/group", {
          data: {
            id_group_ijp: id
          }
        })
        .then(res => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },
    removeJenisKreditOnGroup(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(process.env.VUE_APP_API_SP3 + "master-ijp/jenis-kredit/remove-group", data)
        .then(res => {
          context.dispatch('getSelectJenisKredit', {
            search: ''
          })
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },
    addJenisKreditOnGroup(context, data){
      return new Promise((resolve, reject) => {
        axios.put(process.env.VUE_APP_API_SP3 + "master-ijp/jenis-kredit/add-group", data)
        .then(res => {
          context.dispatch('getSelectJenisKredit', {
            search: ''
          })
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },
    insertTaripIjp(context, data){
      return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_API_SP3 + "master-ijp/tarif-ijp", data)
        .then(res => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },
    updateTaripIjp(context, data){
      return new Promise((resolve, reject) => {
        axios.put(process.env.VUE_APP_API_SP3 + "master-ijp/tarif-ijp", data)
        .then(res => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },
    deleteTaripIjp(context, data){
      return new Promise((resolve, reject) => {
        axios.delete(process.env.VUE_APP_API_SP3 + "master-ijp/tarif-ijp", {
          data: data
        })
        .then(res => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },
  },
}
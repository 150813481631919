export default {
    namespaced: true,
    state: {
        detectErrorServer: 0
    },
    mutations: {
        setDetectErrorServer(state:any, val:any){
            state.detectErrorServer = val
        },
    },
}
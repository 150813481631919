export default {
    namespaced: true,
  
    state: {
        refreshRequest: false,
        status: null,
        searchkey: null,
        current_page: 1
    },
  
    getters: {
    },
  
    mutations: {
        setStatus(state, {key,val}){
            state[key] = val
        },
        resetState(state) {
            state.refreshRequest = false,
            state.status = null,
            state.searchkey = null,
            state.searchkey = 1
        }
    },
  
    actions: {
        
    },  
  }
import axios from 'axios'

export default {
    namespaced: true,
  
    state: {
        name: 'test',
        body:{
            tanggal: new Date(),
            ArrayProfile: [],
            KreditProfile: []
        },
        toggleModalProfilerAdd: false,
        toggleModalKrdtAdd: false,
        arrProfile: [
            
        ],
        arrStrKredit: [
            
        ]
    },
  
    getters: {
    },
  
    mutations: {
        setToggleModal(state, {val, key}){
            if(key == 'kredit'){
                state.toggleModalKrdtAdd = val
            }else{
                state.toggleModalProfilerAdd = val
            }
        },
        UpdateData(state, {val, key}){
            state.body[key] = val
        },
        setVar(state, {key, val}){
            state[key] = val
        },
        setOptField(state, {val, key, prop}){
            if(prop == 'kredit'){
              const foundItem = state.body["KreditProfile"].find((item)=>{
                return item.key_field === key 
              })
              if(!foundItem){
                state.body["KreditProfile"].push({key: key, value: val})
                return
              }
              console.log(foundItem)
      
              let valUpt = ( k,vl ) => {
                for (var i in state.body["KreditProfile"]) {
                  if (state.body["KreditProfile"][i].key_field == k ) {
                    state.body["KreditProfile"][i].value = vl;
                      break; //Stop this loop, we found it!
                  }
                }
              }
      
              valUpt(key,val)
            }else{
              const foundItem = state.body["ArrayProfile"].find((item)=>{
                return item.key_field === key 
              })
              if(!foundItem){
                state.body["ArrayProfile"].push({key: key, value: val})
                return
              }
      
              let valUpt = ( k,vl ) => {
                for (var i in state.body["ArrayProfile"]) {
                  if (state.body["ArrayProfile"][i].key_field == k ) {
                    state.body["ArrayProfile"][i].value = vl;
                      break; //Stop this loop, we found it!
                  }
                }
              }
      
              valUpt(key,val)
            }
          },
          setArrProfile(state, {key, jenis}){
            if(jenis == 'kredit'){
              state.body.KreditProfile.push({key_field: key, value: null})
            }else{
              state.body.ArrayProfile.push({key_field: key, value: null})
            }
          }
    },
  
    actions: {
        ToggleModal(context, {val, key}){
            context.commit('setToggleModal', {val:val, key:key})
        },
        AddForm(context, {akey, variant}){
            context.commit('setArrProfile', {key:akey, jenis: variant})
        },
        // InitEdit(){
        //     axios.get(process.env.VUE_APP_APIORIGIN+'/profiling/'+this.$route.params.id)
        //     .then((e)=>{
        //         context.commit('UpdateData', {key:"form_date" ,val: e.data.data.form_date})
                
        //     })
        // }
    },
    
    
    
  }
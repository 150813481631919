import axios from "axios";
import { ElMessage } from 'element-plus';
import { data } from "autoprefixer";

export default {
    namespaced:true,
    state: {
        mitralist: [],
        searchparams:{
            size:10,
            page:1,
            search:null
        },
        dataTambah:{
            id:"",
            name:"",
            alamat:""
        },
        dataEdit:{
            id:"",
            name:"",
            alamat:""
        },
        dataDetail:{
            id:"",
            name:"",
            alamat:""
        },
        loading: false
    },
    
    getters: {
    },

    mutations: {
        SET_MITRA_LIST(state, value){
            state.mitralist = value.data
            state.pagination = value.pagination
        },
        SET_LOADING(state, value){
            state.loading = value
        },
        RESET_MITRA_FORM(state, typeState){
            if(typeState == "insert"){
                state.dataTambah = {
                    id:"",
                    name: "",
                    alamat: ""
                }
            } else if (typeState == "update") {
                state.dataEdit = {
                    id:"",
                    name: "",
                    alamat: ""
                }
            } else if (typeState == "view") {
                state.dataDetail = {
                    id:"",
                    name: "",
                    alamat: ""
                }
            }
        },
        RESET_STATE(state){
            state.searchparams = {
                size:10,
                page:1,
                search:null
            },
            state.dataTambah = {
                id:"",
                name:"",
                alamat:""
            },
            state.dataEdit = {
                id:"",
                name:"",
                alamat:""
            },
            state.dataDetail = {
                id:"",
                name:"",
                alamat:""
            },
            state.loading = false
        }
    },

    actions: {
        getMitraList(context, token) {
            return new Promise((resolve, reject) => {
                context.commit("SET_LOADING", true)
                const state = context.state
                axios.get(process.env.VUE_APP_API_SP3 + "master-mitra", {
                    params: {
                        size: state.searchparams.size, 
                        page: state.searchparams.page, 
                        search: state.searchparams.search
                    },
                    cancelToken: token
                })
                .then(response =>{
                    context.commit("SET_MITRA_LIST", {
                        data: response.data.data.data,
                        pagination: response.data.data.links
                    })
                    resolve(response.data)
                })
                .catch(err => {
                    reject(err)
                })
                context.commit("SET_LOADING", false)
            })
        },
        postnewMitra(context, value){
            return new Promise((resolve, reject) => {
                context.commit("SET_LOADING", true)
                const state = context.state
                data = {
                    data: value,
                    params: {size:state.searchparams.size, page:state.searchparams.page, search:state.searchparams.search}
                }

                axios.post(process.env.VUE_APP_API_SP3 + "master-mitra", data)
                .then((response) => {
                    context.commit("SET_MITRA_LIST", {
                        data: response.data.data.data,
                        pagination: response.data.data.links
                    })
                    ElMessage({
                        showClose: true,
                        message: 'Berhasil Ditambah!',
                        type: 'success',
                    })
                })
                .catch((error) => {
                    reject(error)
                })
                context.commit("SET_LOADING", false)
            })
        },
        updateMitraList(context, value){
            return new Promise((resolve, reject) =>{
                context.commit("SET_LOADING", true)
                const state = context.state
                data = {
                    data: value,
                    params: {size:state.searchparams.size, page:state.searchparams.page, search:state.searchparams.search}
                }
                axios.put(process.env.VUE_APP_API_SP3 + "master-mitra", data)
                .then((response) =>{
                    context.commit("SET_MITRA_LIST", {
                        data: response.data.data.data,
                        pagination: response.data.data.links
                    })
                    ElMessage({
                        showClose: true,
                        message: 'Berhasil Diedit!',
                        type: 'success',
                    })
                })
                .catch((error) => {
                    reject(error)
                })
                context.commit("SET_LOADING", false)
            })
        },
        deleteMitraList(context, value){
            return new Promise((resolve,reject) =>{
                context.commit("SET_LOADING", true)
                const state = context.state
                data = {
                    data: {
                        id: value
                    },
                    params: {
                        size:state.searchparams.size, 
                        page:state.searchparams.page, 
                        search:state.searchparams.search
                    }
                }
                axios.delete(process.env.VUE_APP_API_SP3 + "master-mitra", {data})
                    .then((response) =>{
                        context.commit("SET_MITRA_LIST", {
                            data: response.data.data.data,
                            pagination: response.data.data.links
                        })
                        ElMessage({
                            showClose: true,
                            message: 'Berhasil Dihapus!',
                            type: 'success',
                        })
                    })
                    .catch((error) => {
                        reject(error)
                    })
                    context.commit("SET_LOADING", false)
                })
            }
        },
    }

export default {
    namespaced: true,
  
    state: {
        name: 'asa',
        toggleModalProfilerAdd: false,
        toggleModalKrdtAdd: false,
        dynamicFieldFormProfiling: [],
        dynamicFieldFormStructurCredit: [],
        activeCustomProfiling: false,
        activeCustomStructurCredit: false
    },
  
    getters: {
    },
  
    mutations: {
        setToggleModal(state, {key, val}){
            state[key] = val
        },
        setvar(state, {key, val}){
            state[key] = val;
        },
        resetState(state){
            state.toggleModalProfilerAdd = false
            state.toggleModalKrdtAdd = false
            state.dynamicFieldFormProfiling = []
            state.dynamicFieldFormStructurCredit = []
            state.activeCustomProfiling = false
            state.activeCustomStructurCredit = false
        },
        setArrProfile(state, {key, val}){
            let tmp = []
            let b = {}
            let c = b["name"]=val
            // console.log(val + key)
            if(key == 'kredit'){
                state.arrStrKredit.push(b)
            }else{
                state.arrProfile.push(b)
            }
        },
        setNewField(state, {typeField, name}){
            const foundItem = state[typeField].find((item)=>{
                return item.name === name 
            })
            if(!foundItem){
                state[typeField].push({
                id_field: 0,
                name: name,
                urutan: state[typeField].length + 1,
                values: "",
                autofill: null,
                symbol: null,
                key_reference: null,
                type_form: null,
                type_input: "text",
                type_field: "textarea",
                required: false,
                disabled: false,
                value: null
                })
            }
        }
    },
    actions: {
        
    }
  }
import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
// import { initVeeValidate } from "@/core/plugins/vee-validate";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import CKEditor from '@ckeditor/ckeditor5-vue';
import "@/core/plugins/prismjs";
import axios from "axios";
import Cookies from "js-cookie";    
import VueExcelXlsx from "vue-excel-xlsx";
import money from 'v-money3'

// import { jwtInterceptor } from '@/_helpers/jwtInterceptor'
const app = createApp(App);

app.mixin({
    methods: {
        evaluateCalculateAutoFill(data) {
          data.forEach((item, index) => {
              if(item.autofill != null && typeof item.autofill === "object") {
                  let evaluate = true;
                  let rumus = '';
        
                  item.autofill.forEach(itemFill => {
                      if(itemFill.type == 'key'){
                          let objReference = data.find(obj => obj.key_reference == itemFill.value)
                          if(objReference.value == null || objReference.value == 0){
                              evaluate = false;
                          }
                          rumus += ' ' +  objReference.value
                      } else {
                          rumus += ' ' +  itemFill.value
                      }
                  });
                  
                  if(evaluate) {
                      item.value = eval(rumus);
                  } else {
                      item.value = 0;
                  }
              }
              return;
          });
        }
    }
})

app.use(store);
app.use(router);
// @ts-ignore
app.use(ElementPlus);
app.use( CKEditor )
app.use(VueSweetalert2);
app.use(VueExcelXlsx);
app.use(money)

axios.interceptors.request.use(request => {
    request.headers.common.Authorization = `Bearer ${Cookies.get('access_token')}`;
    return request;
});
// jwtInterceptor()
ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
// initVeeValidate();

app.use(i18n);

app.mount("#app");

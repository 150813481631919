export default {
    namespaced: true,
  
    state: {
        default_menus:[
            {name: 'Dashboard'}
        ],
        username: 'none',
        user: {
            group_id: "e6d4d027-6ad4-4590-a5a8-0b75fb5d9b2a",
            permission: [
            ]
          } 
    },
  
    getters: {
    },
  
    mutations: {
    },
  
    actions: {
        
    },  
  }
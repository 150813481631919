import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";


export default {
  namespaced: true,

  state: {
    templates: [],
    loading: false,
    answers: [],
    activeProfile : null,
    status : false,
    tahun : [],
    skema: 1
  },

  getters: {
  },

  mutations: {
    SET_TEMPLATE (state, n) {
      state.templates = n
    },
    SET_TAHUN (state, n) {
      state.tahun = n
    },
    SET_ANSWER (state, n) {
        state.answers = n
      }
  },

  actions: {
    setTahun(context, pay){
        context.commit('SET_TAHUN', pay);
    },
    fillMass (context, payload) {
        console.log(payload)
        let answers = context.state.answers;
        let val = payload.value.split(/\n/)
        let row = 0;
        let col = 0;
        let coll = [];
        let temp =0;
        val.forEach(re => {
            console.log("")
            console.log(re)
            col = 0
            coll = re.split(/\t/)
            coll.forEach(ce => {
                temp = parseInt(ce.replaceAll('.','').replaceAll('(', '-').replaceAll(')', ''))
                if (isNaN(temp)) {
                    temp = 0
                }
                console.log(temp)
                answers[payload.group][payload.row + row].answer[payload.column + col] = temp;
                col++
            });
            row++
        });
        
        context.commit('SET_ANSWER', answers)
    },
    fillOne (context, payload) {
        let answers = context.state.answers;
        let val = parseInt(payload.value)
        if (isNaN(val)) {
            val = 0
        }
        answers[payload.group][payload.row].answer[payload.column] = val;
        context.commit('SET_ANSWER', answers)
    },
    async post (context) {
        await axios.post(process.env.VUE_APP_APIORIGIN+'/keuangan/post', {
            id_profiling: context.state.activeProfile,
            years: context.state.tahun,
            data: context.state.answers
        })
        .then(async () => {    
            const dataFour = await axios.get(process.env.VUE_APP_APIORIGIN+'/keuangan/getprofile/'+context.state.activeProfile)
            context.state.status = dataFour.data.exist
            context.state.skema = dataFour.data.skema
            context.dispatch('getTemplates');
        })
        .then(() => {context.state.skema == 1 && context.state.status == false ? "" : context.dispatch('setActive',context.state.activeProfile, {root: true})})
    },
    async getTemplates (context) {
        let array1 = [];
        
        context.state.status ? (
            await axios.get(process.env.VUE_APP_APIORIGIN+'/keuangan/get', {
                params:{
                    skema: context.state.skema,
                    id_profiling: context.state.activeProfile
                }
            })
            .then(res => {
                context.commit('SET_TEMPLATE',res.data.data.template)
                context.commit('SET_ANSWER', res.data.data.answers)
                context.commit('SET_TAHUN', res.data.years)
            })
        ) : (
            await axios.get(process.env.VUE_APP_APIORIGIN+'/keuangan/tmp', {
                params:{
                    skema:context.state.skema,
                }
            })
            .then(res => context.commit('SET_TEMPLATE',res.data.data))
            .then(() => {
                for (let index = 0; index < context.state.templates.length; index++) {
                    array1.push([])
                    for (let i = 0; i < context.state.templates[index].data.length; i++) {
                        array1[index].push(
                            {
                                id: context.state.templates[index].data[i].id_akun_keuangan,
                                answer : [],
                                index : i
                            }
                        )
                        for (let c = 0; c < 3; c++) {
                            array1[index][i].answer.push(null)
                        }
                    }
                } 
            })
            .then(() => {
                context.commit('SET_ANSWER', array1)
            })
        )
    }
  },
  
  
  
}